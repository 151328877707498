

import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";


import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';

import Context from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



const ResExp = (props) => {

    const blankExp = {
        id: 0,
        dates: "Date range",
        title: "Your position",
        employer: "Company",
        where: "City, ST",
        responsibilities: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    };

    const updateDates = (e) => {
        const updatedValue = e.target.value;
        const editMeId = props.job.id;

        props.onType(editMeId, "dates", updatedValue);
    };

    const updateTitle = (e) => {
        const updatedValue = e.target.value;
        const editMeId = props.job.id;

        props.onType(editMeId, "title", updatedValue);
    };

    const updateEmployer = (e) => {
        const updatedValue = e.target.value;
        const editMeId = props.job.id;

        props.onType(editMeId, "employer", updatedValue);
    };

    const updateWhere = (e) => {
        const updatedValue = e.target.value;
        const editMeId = props.job.id;

        props.onType(editMeId, "where", updatedValue);
    };

    const updateResp = (e) => {
        const updatedValue = e.target.value;
        const editMeId = props.job.id;

        props.onType(editMeId, "responsibilities", updatedValue);
    };



    const moveMeUp = () => {
        const editMeOrder = props.job.order;
        props.onMoveUp(editMeOrder);
    };

    const moveMeDown = () => {
        const editMeOrder = props.job.order;
        props.onMoveDown(editMeOrder);
    }

    const deleteJob = () => {
        const deleteMeId = props.job.id;
        props.onDelete(deleteMeId);
    }

    const editorKey = props.job.id * 2;

    const edPlugins = ClassicEditor.builtinPlugins.map(plugin => plugin.pluginName);



    let htmlResponsibilities = { __html: props.job.responsibilities };

    return ((props.preview === true) ?

        <div className="flex-row job-block reveal">
            <div className="flex-col date-col">
                {props.job.dates}
            </div>
            <div className="job-col">
                <h4>{props.job.title}</h4>
                <h5>{props.job.employer} | {props.job.where}</h5>
                <div dangerouslySetInnerHTML={htmlResponsibilities}>
                </div>

            </div>
        </div>

        :

        <div className="flex-row hunny-p">

        <div className="flex-col edit-res-exp eighty-p">
            <h5>Start Mon. YY - End Mon. YY:</h5>
            <input type="text" value={props.job.dates} onChange={updateDates} />
            <h5>Your position:</h5>
            <input type="text" value={props.job.title} onChange={updateTitle} />
            <h5>Company name:</h5>
            <input type="text" value={props.job.employer} onChange={updateEmployer} />
            <h5>Location:</h5>
            <input type="text" value={props.job.where} onChange={updateWhere} />

            <h5>Your Responsibilities:</h5>
            <CKEditor
                editor={ClassicEditor}
                config={{
                    initialData: props.job.responsibilities,
                    plugins: ["Essentials",
                        "Bold",
                        "Italic",
                        "Indent",
                        "Link",
                        "List",
                        "Paragraph",
                        "PasteFromOffice"],
                    toolbar: [
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "|",
                        "outdent",
                        "indent",
                        "|",
                        "undo",
                        "redo"]
                }}

                onChange={(event, editor) => {
                    const data = editor.getData();
                    const editMeId = props.job.id;

                    props.onType(editMeId, "responsibilities", data);
                }}

                onReady={editor => {
                    // You can store the "editor" and use when it is needed.



                }}
            />


            </div>

            <div className="flex-col">
                
                <span className="span-button" onClick={moveMeUp}>^</span>
                <span className="span-button" onClick={moveMeDown}>v</span>
                <span className="span-button" onClick={deleteJob}>x</span>

            </div>
        </div>
    )




}




export default ResExp;
