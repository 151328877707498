

import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";


const ResProject = (props) => {

    const blankProject = {
        id: 0,
        title: "Project title",
        url: "projecturl.com",
        desc: "short description",
        tools: "tools used"
    };

    const updateTitle = (e) => {
        const updatedValue = e.target.value;
        const editMeId = props.project.id;
        props.onType(props.projects, editMeId, "title", updatedValue);
    };

    const updateURL = (e) => {
        const updatedValue = e.target.value;
        const editMeId = props.project.id;
        props.onType(props.projects, editMeId, "url", updatedValue);
    };

    const updateDesc = (e) => {
        const updatedValue = e.target.value;
        const editMeId = props.project.id;
        props.onType(props.projects, editMeId, "desc", updatedValue);
    };

    const updateTools = (e) => {
        const updatedValue = e.target.value;
        const editMeId = props.project.id;
        props.onType(props.projects, editMeId, "tools", updatedValue);
    };

 
    return ((props.preview===true) ?

        <div key={props.project.id} className="featured-project reveal">

            <p><Link to={props.project.url} target="_blank">{props.project.title}:</Link></p>
            
            
            <p>{props.project.desc}</p>
            <p><b>Tools used - </b>{props.project.tools}
            </p>
        </div>

        :

        <div className="flex-row hunny-p edit-res-proj">
            

            <div className="flex-col eighty-p">
                <h5>Project title:</h5>
                <input type="text" value={props.project.title} onChange={updateTitle} />
                <h5>Link to project:</h5>
                <input type="text" value={props.project.url} onChange={updateURL} />
                <h5>Project description:</h5>
                <textarea value={props.project.desc} onChange={updateDesc}></textarea>
                <h5>Tools used:</h5>
                <input type="text" value={props.project.tools} onChange={updateTools} />
            </div>
            <span className="span-button" onClick={props.onDelete}>x</span>

        </div>
    )




}




export default ResProject;
