import React from "react";
import ResColorsList from "./ResColorsList.js";
import { BrowserRouter, Route, Link } from "react-router-dom";
import CSS_COLOR_NAMES from "../cssColorNames.js";


const ColorPicker = (props) => {

    const pickThisColor = () => {
        let selOpt = document.getElementById("color-preview").style.backgroundColor;
        props.onChangeColor(props.variable, selOpt);
    }

    const onTypeHex = (e) => {
        //props.onChangeColor(props.variable, e.target.value);
        previewColor(e.target.value);
    }

    const previewColor = (selOpt) => {
        document.getElementById("color-preview").style.backgroundColor = selOpt;
        document.getElementById("txt-color").value = selOpt;
    }

    let idAry = [];
    const renderColorOptions = (colorOpt) => {

        let theKey = Math.floor(Math.random() * 10000);

        do {
            theKey = Math.floor(Math.random() * 10000);
        } while (idAry.includes(theKey) === true);

        idAry.push(theKey);


        return (
            <li key={theKey} onClick={() => previewColor(colorOpt)}>
                <span className="color-option-preview" style={{ backgroundColor: colorOpt }}/>
                {colorOpt}
            </li>
        )
    }

    const updatedOptions = CSS_COLOR_NAMES.map(renderColorOptions);

    const closeThis = () => {
        document.getElementById("color-picker").classList.add("illuminati");
    }

    return (

        <div className="color-picker illuminati" id="color-picker">

            <div className="flex-row">

            <div id="color-preview" className="flex-row">
                <svg width="50" height="50" viewBox="0 0 24 24" className="heart" onClick={pickThisColor}>
                    <path d="M12 4.419c-2.826-5.695-11.999-4.064-11.999 3.27 0 7.27 9.903 10.938 11.999 15.311 2.096-4.373 12-8.041 12-15.311 0-7.327-9.17-8.972-12-3.27z" />
                </svg>
                </div>
                <span className="span-button" onClick={closeThis}>X</span>
            </div>

            <div className="flex-col">
                Enter a color code or select one from the list.
                <input id="txt-color" type="text" onChange={onTypeHex} />
            </div>

            <ul>{updatedOptions}</ul>
        </div>

    )
};

export default ColorPicker;
