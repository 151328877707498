import React from "react";
import ResEdu from "./ResEdu.js";

const ResEduList = (props) => {

    const renderSchools = (edu) => {

        return (

            <ResEdu key={edu.id} education={props.education} school={edu} onType={props.onType} onDelete={props.onDelete} preview={props.preview} />
        );
    }


    const eduList = props.schools.map(renderSchools);



    return <div className="hunny-p flex-col">
        <span className="span-button" onClick={props.onAdd} style={{ display: !props.preview ? 'initial' : 'none' }}>+ add credentials</span>

        {eduList}</div>
};

export default ResEduList;
