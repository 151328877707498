import React, { Component } from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Link } from "react-router-dom";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import '../App.css';
import ResLinkList from './ResLinkList.js';
import ResProjectList from './ResProjectList.js';
import ResEduList from './ResEduList.js';
import ResExpList from './ResExpList.js';
import ResColorsList from "./ResColorsList.js";



const defaultColors = [{
    name: "--primary-color",
    color: "#84A59D"
},
    {
        name: "--dark-text",
        color: "#351d77"
    },
    {
        name: "--light-bg-main",
        color: "#e8f0e4"
    },
    {
        name: "--dark-bg",
        color: "#002060"
    },
    {
        name: "--accent-text",
        color: "#f28482"
    }, {
        name: "--light-bg-accent",
        color: "#f7ede2"
    }]

    


const blankHuman = {
    name: "Orville Tootenbacher",
    title: "Michael Scott Impersonator",
    phone: "555-555-5555",
    email: "hello@youremail.com",
    city: "Scranton, PA",
    bio: "I am a fan of the outrageous. I love to surprise. I love other things as well.",
    skills: "World's Best Boss, Skilled Magician, Song Parody Writer",
    pic: ""
};

const blankLink = {
    id: 0,
    title: "My Blog",
    url: "https://creedthoughtsgov.com/"
};

const blankProject = {
    id: 0,
    title: "Somehow I Manage",
    url: "https://www.redbubble.com/i/notebook/Somehow-I-Manage-by-mlaudym/95834324.RXH2R?country_code=US&gclid=CjwKCAiA_vKeBhAdEiwAFb_nrcrmewMO5T9hXxAjdKa6M8L3p0ZmljNaIJIdz-F5AFoM3IZrg8I4kxoCXcYQAvD_BwE&gclsrc=aw.ds",
    desc: "A story with heart and soul about my management style and the hilarious hijinks I get myself into.",
    tools: "PowerPoint"
};

const blankEdu = {
    id: 0,
    school: "School of Hard Knocks",
    cred: "Survival",
    yr: "2023"
};

const blankExp = {
    id: 0,
    dates: "2012 - Now",
    title: "Michael Scott Impersonator for Hire",
    employer: "Orville Tootenbacher Productions",
    where: "Scranton, PA",
    responsibilities: "<ul><li>Entertain!</li><li>Dazzle!</li><li>Amaze!</li></ul>",
    order: 0
};

const blankExp2 = {
    id: 1,
    dates: "2010 - 2011",
    title: "Sales",
    employer: "Men's Wearhouse",
    where: "Scranton, PA",
    responsibilities: "<ul><li>Greeted customers</li><li>Sold suits.</li></ul>",
    
    order: 0
};


class ResTemplate extends Component {

    state = {
        human: blankHuman,
        links: [blankLink],
        projects: [blankProject],
        education: [blankEdu],
        schools: [blankEdu],
        skillView: "list",
        jobs: [blankExp, blankExp2],
        colors: defaultColors,
        selectedColorVar: "",
        resetWhat: "",

        preview: true
    };

    blankState = this.state;

    componentDidUpdate() {
        const stateString = JSON.stringify(this.state);
        localStorage.setItem("stateString", stateString);
        this.onUpdateColors();
    }

    componentDidMount() {
    document.title = "BYO Resume!"
        const stateString = localStorage.getItem("stateString");
        if (stateString) {
            const savedState = JSON.parse(stateString);

            if (savedState.human.pic.indexOf("blob") === 0) {
                savedState.human["pic"] = "";
            }
            this.setState(savedState);

            this.writeSkillView(savedState.skillView);
            document.getElementById(savedState.skillView).classList.add("selected");

            this.onUpdateColors();

            let selListView = savedState.skillView;
            document.getElementById(selListView).classList.add("selected");

            if (savedState.preview === true) {
                //document.getElementById("preview-view").classList.add("selected");
                //document.getElementById("edit-view").classList.remove("selected");
                this.onPreview();
            } else {
                //document.getElementById("edit-view").classList.add("selected");
                //document.getElementById("preview-view").classList.remove("selected");
                this.onEdit();
            }
        }

        //fetch("https://jsonplaceholder.typicode.com/users")
        //fetch("https://mybusiness.googleapis.com/v4/accounts/AIzaSyB8exXP6LFLGLjjDq0IDL8ggpE6h2_xwu4/locations/ChIJjYRPWMcbiYgRlhsvWfur8ME/reviews")
        fetch("https://mybusiness.googleapis.com/v4/accounts/25285050332-i2435p2nh35k4ft3ms98g16f52fjldbn.apps.googleusercontent.com/locations/ChIJjYRPWMcbiYgRlhsvWfur8ME/reviews")
            .then((res) => res.json())
            .then((json) => {
            console.log(json)
                this.setState({
                    items: json,
                    DataisLoaded: true
                });
            })



        //console.log(this.blankState)
        //this.setState({
        //    human: blankHuman,
        //    links: [blankLink],
        //    projects: [blankProject],
        //    education: [blankEdu],
        //    schools: [{
        //        id: 0,
        //        school: "School or institution",
        //        cred: "Certificate or Degree",
        //        yr: "Date or year of completion"
        //    }],
        //    experience: [blankExp],
        //    skillView: "paragraph",
        //    skillViewElements: "",
        //    jobs: [blankExp],
        //    colors: defaultColors,
        //    selectedColorVar: "",
        //    resetWhat: "",

        //    preview: false
        //});

       
    }

    changeSkillView = (e) => {
        this.writeSkillView(e.target.innerText);
        if (e.target.classList.contains("selected")===false) {
            document.getElementById("paragraph").classList.toggle("selected");
            document.getElementById("list").classList.toggle("selected");
        }
        

    }

    writeSkillView = (theView) => {
        
        let elem;
        if (theView === "list") {
            let skills = this.state.human.skills;
            let listSkills = skills.split(",");
            elem = document.createElement("ul");
            elem.classList.add("flex-row", "template-reveal");
            for (let i = 0; i < listSkills.length; i++) {
                const li = document.createElement("li");
                li.classList.add("template-reveal");
                li.innerText = listSkills[i];
                elem.appendChild(li);
            }
        } else {
            elem = document.createElement("p");
            elem.classList.add("template-reveal");
            elem.innerText = this.state.human.skills;
        }
        this.setState({ skillView: theView})
        document.getElementById("skill-block").innerText = ""
        document.getElementById("skill-block").appendChild(elem);
    }

    onPreview = () => {
        this.setState({ preview: true });
        document.getElementById("button-slider").classList.add("closed");

        const elements = document.querySelectorAll('.template-reveal');
        elements.forEach(el => { el.classList.remove("template-reveal"); el.classList.add("reveal","active") });

        document.getElementById("edit-view").classList.remove("selected");
        document.getElementById("preview-view").classList.add("selected");
};

    onEdit = () => {
        this.setState({ preview: false });
        document.getElementById("preview-view").classList.remove("selected");
        document.getElementById("edit-view").classList.add("selected");

        const elements = document.querySelectorAll('.reveal');
        elements.forEach(el => { el.classList.add("template-reveal"); el.classList.remove("reveal") });
    }
    

    onMoveUp = (editMeOrder) => {
        const updatedJobs = this.state.jobs.map((obj) => {
            if (obj.order === editMeOrder) {
                obj["order"] = editMeOrder + 1;
            } else if (obj.order == editMeOrder + 1) {
                obj["order"] = editMeOrder;
            }
            return obj;
        });

        const numDescending = [...updatedJobs].sort((a, b) => b.order - a.order);



        this.setState({jobs: numDescending})
    }

    onMoveDown = (editMeOrder) => {
        const updatedJobs = this.state.jobs.map((obj) => {
            if (obj.order === editMeOrder) {
                obj["order"] = editMeOrder - 1;
            } else if (obj.order == editMeOrder - 1) {
                obj["order"] = editMeOrder;
            }
            return obj;
        });

        const numDescending = [...updatedJobs].sort((a, b) => b.order - a.order);



        this.setState({ jobs: numDescending })
    }


    onType = (ary, editMeId, updatedKey, updatedValue) => {
        const updatedNotes = ary.map((obj) => {
            if (obj.id === editMeId) {
                obj[updatedKey] = updatedValue;
            }
            return obj;
        });

        this.setState({ links: updatedNotes });
    };



    onTypeJobs = ( editMeId, updatedKey, updatedValue) => {
        const updatedJobs = this.state.jobs.map((obj) => {
            if (obj.id === editMeId) {
                obj[updatedKey] = updatedValue;
            }
            return obj;
        });

        this.setState({ jobs: updatedJobs });
    };

    onTypeHuman = (key, updatedValue) => {
        let human = this.state.human;

        human[key] = updatedValue;

        this.setState({ human: human });
    };

    onTypeName = (e) => {
        let human = this.state.human;

        human["name"] = e.target.value;

        this.setState({ human: human });
    }

    onTypeTitle = (e) => {
        let human = this.state.human;
        let updatedValue = e.target.value;

        human["title"] = updatedValue;

        this.setState({ human: human });
    }

    onTypePhone = (e) => {
        let human = this.state.human;
        let updatedValue = e.target.value;

        human["phone"] = updatedValue;

        this.setState({ human: human });
    }

    onTypeEmail = (e) => {
        let human = this.state.human;
        let updatedValue = e.target.value;

        human["email"] = updatedValue;

        this.setState({ human: human });
    }

    onTypeCity = (e) => {
        let human = this.state.human;
        let updatedValue = e.target.value;

        human["city"] = updatedValue;

        this.setState({ human: human });
    }

    onTypeSkills = (e) => {
        let human = this.state.human;
        let updatedValue = e.target.value;

        human["skills"] = updatedValue;

        this.setState({ human: human });
        this.writeSkillView(this.state.skillView);
    }

    onTypeBio = (e) => {

        let human = this.state.human;
        let updatedValue = e.target.value;

        human["bio"] = updatedValue;

        this.setState({ human: human });
    };

    onTypeProject = (ary, editMeId, updatedKey, updatedValue) => {
        const updatedProjects = ary.map((obj) => {
            if (obj.id === editMeId) {
                obj[updatedKey] = updatedValue;
            }
            return obj;
        });

        this.setState({ projects: updatedProjects });
    };

    onTypeEdu = (ary, editMeId, updatedKey, updatedValue) => {
        const updatedEducation = this.state.schools.map((obj) => {
            if (obj.id === editMeId) {
                obj[updatedKey] = updatedValue;
            }
            return obj;
        });

        
        this.setState({ schools: updatedEducation });
    };

    addLink = () => {
        const resLink = {
            id: Date.now(),
            title: "title",
            url: "linkurl.com"
        };
        const newLinks = [resLink, ...this.state.links];
        this.setState({ links: newLinks });
    };

    addProject = () => {
        const resProject = {
            id: Date.now(),
            title: "Project title",
            url: "projecturl.com",
            desc: "short description",
            tools: "tools used"
        };
        const newProjects = [resProject, ...this.state.projects];
        this.setState({ projects: newProjects });
    };

    onAddEdu = () => {
        const resEdu = {
            id: Date.now(),
            school: "",
            cred: "",
            yr: ""
        };
        const newEdu = [resEdu, ...this.state.schools];
        this.setState({ schools: newEdu });
    };

    onAddJob = () => {
        const job = {
            id: Date.now(),
            dates: "Mon. YYYY - Mon. YYYY",
            title: "Your position",
            employer: "Company",
            where: "City, ST",
            responsibilities: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            order: this.state.jobs.length
        };
        const newJobs = [job, ...this.state.jobs];

        const numDescending = [...newJobs].sort((a, b) => b.order - a.order);


        //sort newJobs by order

        this.setState({ jobs: numDescending });
    };


    deleteLink = (deleteMeId) => {
        const x = this.state.links.findIndex((obj) => obj.id === deleteMeId);
        let ary = this.state.links;
        ary.splice(x, 1);

        this.setState({ links: ary });
    }

    deleteProject = (deleteMeId) => {
        const x = this.state.projects.findIndex((obj) => obj.id === deleteMeId);
        let ary = this.state.projects;
        ary.splice(x, 1);

        this.setState({ projects: ary });
    }

    onDeleteEdu = (deleteMeId) => {
        const x = this.state.schools.findIndex((obj) => obj.id === deleteMeId);
        let ary = this.state.schools;
        ary.splice(x, 1);
        this.setState({ schools: ary });
    }

    onDeleteJob = (deleteMeId) => {
        const x = this.state.jobs.findIndex((obj) => obj.id === deleteMeId);
        let ary = this.state.jobs;
        ary.splice(x, 1);
        let i = -1;
        const updateOrders = ary.map((obj) => {
            i++;
                obj["order"] = i;
            return obj;
        });

        this.setState({ jobs: ary });
    }



    changePrimary = () => {
        document.documentElement.style.setProperty("--accent-text", "purple");
    }

    
    onUpdateColors = () => {

        for (let i = 0; i < this.state.colors.length; i++) {
            document.documentElement.style.setProperty(this.state.colors[i].name, this.state.colors[i].color);
        }
    }

    onResetColors = () => {
        this.setState({ resetWhat: "colors" });
        this.showConfirm();
    }

    pickThisColor = (theVar, theColor) => {
        let cols = this.state.colors.map((colorObj) => {
            if (colorObj.name == theVar) {
                colorObj["color"] = theColor;
            }
            return colorObj;
        })
        this.setState({ colors: [...cols] })
        this.onUpdateColors();
    }


    onClickPaletteItem = (colorVar, colorColor) => {
        this.setState({ selectedColorVar: colorVar });
        document.getElementById("color-preview").style.backgroundColor = colorColor;
        document.getElementById("color-picker").classList.remove("illuminati");
        document.getElementById("txt-color").value = colorColor;
    }

    toggleColors = () => {

        document.getElementById("button-slider").classList.toggle("closed");
        //document.getElementById("buttons-button").classList.toggle("bounce");
        
    }

   

    handleChange = (e) => {
        let human = this.state.human;
        human["pic"] = URL.createObjectURL(e.target.files[0]);
        this.setState({ human: human  });
    }

    backToTop = () => {
        document.documentElement.scrollTop = 0;
    }

    clearImage = () => {
        let human = this.state.human;
        human["pic"] = "";
        this.setState({ human: human })
    }

    startOver = () => {
        this.setState({ resetWhat: "human" });
        this.showConfirm();
    }

    showConfirm = () => {
         document.getElementById("confirmation-box").classList.remove("illuminati");
         document.getElementById("inner-conf").classList.add("bounce");
    };

     handleConfirmation = (confirmation) => {
         if (confirmation) {
             // do something if the user confirms
             //if resetWhat==="colors", 
             const rw = this.state.resetWhat;
             if (rw === "colors") {
                 this.setState({
                     colors: defaultColors,
                     resetWhat: ""
                 });
                 this.onUpdateColors();
             }
             //this.setState({ colors: defaultColors });
             //this.onUpdateColors();
             //else if "human"
         else if (rw === "human") {
                 //const savedState = JSON.parse(stateString);
                 //this.setState(savedState);
                 this.setState(this.blankState);
            }
             
        } else {
            // do something if the user cancels
            //hide confirmation-box
            //setState resetWhat=""
            this.setState({ resetWhat: "" });
            
         }

         document.getElementById("confirmation-box").classList.add("illuminati");
         document.getElementById("inner-conf").classList.remove("bounce");
    }

    resizeImage = () => {
        //icon-of-me
        document.getElementById("icon-of-me").classList.toggle("fit-to-circ");
        document.getElementById("resize-img").classList.toggle("selected");
    }



    render() {
        return (

        <div>
            
                <div id="button-slider" className="closed flex-col">

                    <div className="flex-row info-blurb">
                        <p>Wanna change our colors? It's easy! Simply click on the color you want to switch, choose a new hue from the list that appears, and then give it a tap of the heart to save your new look! Voila! Now they're YOUR colors.</p>
                        <span className="span-button" onClick={this.toggleColors}>x</span>
                    </div>
                    
            <span className="span-button" onClick={this.onResetColors}>Reset Colors</span>
            <ResColorsList colors={this.state.colors} onChangeColor={this.pickThisColor} onClickPaletteItem={this.onClickPaletteItem} colorVar={this.state.selectedColorVar} />
                    
        </div>
                <div id="confirmation-box" className="illuminati">
                    <div className="hunny-p flex-col">

                    <div id="inner-conf" className="inner-conf">

                        <p id="conf-msg">

                            {this.state.resetWhat === "colors" ? "Are you sure you want to replace your artistry with the original color palette?" : "You wanna reset it all, like, EVERYTHING?!"}

                        </p>
                        <div className="flex-row">
                        <span className="span-button xl" onClick={() => this.handleConfirmation(true)}>Proceed!</span>
                        <span className="span-button xl" onClick={() => this.handleConfirmation(false)}>Nevermind</span>
                        </div>
                    </div>
                </div>
                </div>

                <div id="always-visible" className="flex-col">
               

                    <span className="span-button shade" onClick={this.onPreview} title="Preview!" id="preview-view"><i className="fa-solid fa-eye"></i></span>
                    <span className="span-button shade" onClick={this.onEdit} title="Edit fields" id="edit-view"><i className="fa-solid fa-edit"></i></span>
                    <span className="span-button" onClick={this.toggleColors} title="Change color palette"><i className="fa-solid fa-palette"></i></span>
                    <span className="span-button" onClick={this.startOver} title="Start over"><i className="fa-solid fa-redo"></i></span>

                 

                    <span className="span-button" title="Back to top" onClick={this.backToTop}><i className="fa-solid fa-arrow-up"></i></span>
                    
                </div>
                
                

            <div id="resume">
                <div className="header-row flex-row">
                    <div className="flex-row name-block">
                            <div className="icon-block" style={{ display: this.state.human.pic === "" ? 'none' : 'initial' }}>

                                <figure className="flex-col">
                                    <img id="icon-of-me" alt="a picture of me" src={this.state.human.pic} />
                            </figure>
                        </div>

                            <div style={{ display: this.state.preview ? 'initial' : 'none' }}>
                                
                            <h1 className="mint">{this.state.human.name}</h1>
                            <h2>{this.state.human.title}</h2>
                        </div>

                       
                            <div className="edit-buttons" style={{ display: !this.state.preview ? 'initial' : 'none' }}>
                                <div className="flex-row">

                                    <input type="file" onChange={this.handleChange} />
                                    <span id="resize-img" className="span-button" onClick={this.resizeImage} title="Fit image within the circle" style={{ cursor: this.state.human.pic === ""  ? 'not-allowed' : 'pointer', opacity: this.state.human.pic === ""  ? .5 : 1  }}>
                                        <i className="fa-solid fa-compress-arrows-alt"></i>
                                    </span>
                                    <span className="span-button" onClick={this.clearImage} title="Remove the uploaded image">
                                        <i className="fa-solid fa-trash"></i>
                                    </span>
                                    
                                </div>
                            <input type="text" value={this.state.human.name} onChange={this.onTypeName} />
                            <input type="text" value={this.state.human.title} onChange={this.onTypeTitle} />
                        </div>

                    </div>

                    <div className="flex-col contact-block template-reveal">

                        <div className="flex-row">
                            <p className="mint"><i className="fa-solid fa-mobile-alt"></i></p>
                                <p style={{ display: this.state.preview ? 'initial' : 'none' }}>
                                    {this.state.human.phone}
                                </p>
                                <p style={{ display: !this.state.preview ? 'initial' : 'none' }}>
                                    <input type="text" value={this.state.human.phone} onChange={this.onTypePhone} />
                            </p>
                        </div>
                        <div className="flex-row">
                            <p className="mint"><i className="fa-solid fa-envelope-open-text"></i></p>
                                <p style={{ display: this.state.preview ? 'initial' : 'none' }}>{this.state.human.email}</p>
                                <p style={{ display: !this.state.preview ? 'initial' : 'none' }}><input type="text" value={this.state.human.email} onChange={this.onTypeEmail} /></p>
                        </div>
                        <div className="flex-row">
                            <p className="mint"><i className="fa-solid fa-map-marker-alt"></i></p>
                                <p style={{ display: this.state.preview ? 'initial' : 'none' }}>{this.state.human.city}</p>
                                <p style={{ display: !this.state.preview ? 'initial' : 'none' }}><input type="text" value={this.state.human.city} onChange={this.onTypeCity} /></p>
                        </div>

                        <div className="edit-buttons" style={{ display: !this.state.preview ? 'initial' : 'none' }}>
                            
                            
                        </div>
                    </div>
                </div>


                <div className="bio-row flex-row">
                    <div className="flex-col bio-block">
                        <h3 className="mint template-reveal">Bio</h3>
                        <p className="edit-buttons" style={{ display: !this.state.preview ? 'initial' : 'none' }}>
                            <textarea value={this.state.human.bio} onChange={this.onTypeBio}></textarea>
                            
                        </p>
                        <p className="template-reveal" style={{ display: this.state.preview ? 'initial' : 'none' }}>
                            {this.state.human.bio}
                        </p>

                        <div className="edit-buttons span-button" onClick={this.addLink} style={{ display: !this.state.preview ? 'initial' : 'none' }}>+ add featured links</div>

                        <ResLinkList onType={this.onType} onDelete={this.deleteLink} links={this.state.links} preview={this.state.preview} />



                    </div>

                    <div className="flex-col project-block">
                        <h3 className="mint template-reveal">Projects</h3>

                        <div>
                            <ResProjectList projects={this.state.projects} preview={this.state.preview} onType={this.onTypeProject} onAdd={this.addProject} onDelete={this.deleteProject} />
                        </div>


                    </div>

                </div>

                <div className="skills-and-exp-row flex-row">

                    <div className="blue-block flex-col">
                            <h3 className="mint template-reveal">Skills &amp; Tech Savvy</h3>
                            <div style={{ display: !this.state.preview ? 'initial' : 'none' }}>

                                <span onClick={this.changeSkillView} id="paragraph" className="span-button paragraph shade" title="Display your skills as a paragraph.">paragraph</span>
                                <span onClick={this.changeSkillView} id="list" className="span-button list shade" title="Separate your skills with commas and we'll split them up into separate items!">list</span>

                            </div>

                        <div className="edit-buttons" style={{ display: !this.state.preview ? 'initial' : 'none' }}>
                                <textarea value={this.state.human.skills} onChange={this.onTypeSkills}></textarea><br />
                            <span><u>Preview:</u></span>
                        </div>


                            <div id="skill-block" className="skill-block template-reveal" >




                            {/*<ul className="flex-row template-reveal">*/}
                            {/*    <li>HTML5/CSS3</li>*/}
                            {/*    <li>JavaScript</li>*/}
                            {/*    <li>React</li>*/}
                            {/*    <li>Flexbox</li>*/}
                            {/*    <li>APIs</li>*/}
                            {/*    <li>SQL</li>*/}
                            {/*    <li>VB.NET</li>*/}
                            {/*    <li>WordPress</li>*/}
                            {/*    <li>Git/GitHub</li>*/}
                            {/*    <li>CodeSandbox</li>*/}
                            {/*    <li>SSMS</li>*/}
                            {/*    <li>Visual Studio</li>*/}
                            {/*    <li>Photoshop</li>*/}
                            {/*    <li>Google/MS Suite</li>*/}
                            {/*</ul>*/}



                        </div>

                        <div className="edu-block flex-col">
                            <h3 className="mint template-reveal">Education</h3>

                            <ResEduList schools={this.state.schools} education={this.state.education} onType={this.onTypeEdu} onDelete={this.onDeleteEdu} onAdd={this.onAddEdu} preview={this.state.preview} />

                        </div>



                    </div>

                    <div className="flex-col exp-block">
                        <h3 className="mint template-reveal">Work Experience</h3>
                        
                        <ResExpList preview={this.state.preview} jobs={this.state.jobs} onType={this.onTypeJobs} onAdd={this.onAddJob} onDelete={this.onDeleteJob} onMoveUp={this.onMoveUp} onMoveDown={this.onMoveDown} />
                    </div>

                </div>



            </div>
            </div>

        );
    }
}
export default ResTemplate;