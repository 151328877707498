import React from "react";
import ResColor from "./ResColor.js"
import ColorPicker from "./ColorPicker.js"




const ResColorsList = (props) => {

    let idAry = [];
    const renderColor = (aColorObj) => {
        let theKey = Math.floor(Math.random() * 10000);

        do {
            theKey = Math.floor(Math.random() * 10000);
        } while (idAry.includes(theKey) === true);

        idAry.push(theKey);
        return <ResColor key={theKey} color={aColorObj} colors={props.colors} onChangeColor={props.onChangeColor} pickers={props.pickers} onClickPaletteItem={props.onClickPaletteItem} colorVar={props.colorVar}
        />
    }


    const newColorList = props.colors.map(renderColor)

    return (

        <div>
            <div className="flex-row color-list">
                {newColorList}
            </div>

            <div>
                <ColorPicker variable={props.colorVar} onChangeColor={props.onChangeColor} />

                </div>
            
        </div>
    )
}

export default ResColorsList;
