import React from "react";
import ResExp from "./ResExp.js";
import { BrowserRouter, Route, Link } from "react-router-dom";




const ResExpList = (props) => {

    const reorderJobs = (editMeOrder) => {

        if (editMeOrder < (props.jobs.length - 1)) {
            props.onMoveUp(editMeOrder)
        }


    }

    const moveThisDown = (editMeOrder) => {
        if (editMeOrder > 0) {
            props.onMoveDown(editMeOrder)
        }
    }

    const renderJobs = (job) => {

        return (
            <ResExp key={job.id} preview={props.preview} job={job} onType={props.onType} onDelete={props.onDelete} onMoveUp={reorderJobs} onMoveDown={moveThisDown} />
            )
        }

    const updatedJobs = props.jobs.map(renderJobs);

    const numDescending = [...updatedJobs].sort((a, b) => b.order - a.order);

    

    return (

        <div className="flex-col">
            
            <span className="span-button" onClick={props.onAdd} style={{ display: !props.preview ? 'initial' : 'none' }}>+ add work experience</span>
            {numDescending}
        </div>



    )
};

export default ResExpList;
