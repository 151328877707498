import React from "react";
import ColorPicker from "./ColorPicker.js";

const ResColor = (props) => {


    const handleClick = event => {

        props.onClickPaletteItem(props.color.name, props.color.color);
    };

    const className = props.color.name;

    return <div style={{ background: props.color.color }} onClick={handleClick} id={props.color.name} className="palette-item">
    </div>
};

export default ResColor;
