import React from "react";
import ResProject from "./ResProject.js";
import { BrowserRouter, Route, Link } from "react-router-dom";


const ResProjectList = (props) => {

    const renderLink = (project) => {

        return (

            <ResProject key={project.id} projects={props.projects} project={project} onType={props.onType} onDelete={props.onDelete} preview={props.preview} />
        );
    }


    const linkElements = props.projects.map(renderLink);




    return (

        <div className="flex-col hunny-p">
            <span className="span-button" onClick={props.onAdd} style={{ display: !props.preview ? 'initial' : 'none' }}>+ add featured project</span>
            {linkElements}
        </div>

)
};

export default ResProjectList;
