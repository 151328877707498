import React from "react";

const ResLink = (props) => {

    const updateTitle = (e) => {
        const updatedValue = e.target.value;
        const editMeId = props.link.id;
        props.onType(props.links, editMeId,"title", updatedValue);
    };

    const updateURL = (e) => {
        const updatedValue = e.target.value;
        const editMeId = props.link.id;
        props.onType(props.links, editMeId, "url", updatedValue);

    };

    const deleteThis = () => {
        props.onDelete(props.link.id);
    };

    return ((props.preview === true)
        ?
        <a href={props.link.url} target="_blank">{props.link.title}</a>
        :
        <div key={props.link.id} className="flex-row hunny-p edit-res-link">
            <div className="flex-col eighty-p">
                <h5>Display:</h5>
                <input type="text" value={props.link.title} placeholder="Link title" onChange={updateTitle} />
                <h5>URL:</h5>
                <input type="text" value={props.link.url} onChange={updateURL} placeholder="url" />
            </div>
            <span className="span-button" onClick={deleteThis}>x</span>
                    
        
        
        
    </div>)

    
    }

    


export default ResLink;
