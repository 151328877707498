import React from "react";

const ResEdu = (props) => {
    const updateSchool = (e) => {
        const updatedValue = e.target.value;
        const editMeId = props.school.id;
        props.onType(props.school, editMeId, "school", updatedValue);
    };

    const updateCred = (e) => {
        const updatedValue = e.target.value;
        const editMeId = props.school.id;
        props.onType(props.school, editMeId, "cred", updatedValue);

    };

    const updateYr = (e) => {
        const updatedValue = e.target.value;
        const editMeId = props.school.id;
        props.onType(props.school, editMeId, "yr", updatedValue);

    };

    const deleteThis = () => {
        props.onDelete(props.school.id);
    };

    return ((props.preview === true) ?

        <p className="reveal active">
            <b>{props.school.school}</b><br />
            {props.school.cred}, {props.school.yr}
        </p>

        :


        <div key={props.school.id} className="flex-col edit-res-edu">
            <span className="span-button" onClick={deleteThis}>
                X
            </span>

            <div className="flex-col">

            <h5>School or institution:</h5>
            <input
                type="text"
                value={props.school.school}
                placeholder="School or Institution"
                onChange={updateSchool}
            />
            <h5>Certificate or degree:</h5>
            <input
                type="text"
                value={props.school.cred}
                placeholder="Certificate or Degree"
                onChange={updateCred}
            />
            <h5>Year of completion:</h5>
            <input
                type="text"
                value={props.school.yr}
                placeholder="Year of Completion"
                onChange={updateYr}
            />
            
        </div>
        </div>

    );
};

export default ResEdu;
