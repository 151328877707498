const projects = [
    {
        id: 0,
        title: "Resume Template",
        link: "/template",
        thumbnail: "img/res-template-thumbnail.png",
        skills: ["ES6", "JSX"],
        tools: ["Visual Studio"],
        description: "I turned my resume into a template for users to personalize. Enter your data & pick your own colors! Then close the page. Then come back! The app uses state to remember their settings. Go see it for yourself, I'm pretty proud of this one."
    },

    {
        id: 1,
        title: "Guess the Word",
        link: "https://cbsumo305.github.io/guess-the-word/",
        thumbnail: "img/guess-the-word-thumbnail.png",
        skills: ["JavaScript"],
        tools: ["CodeSandbox", "GitHub"],
        description: "Players guess the word by entering one letter at a time. If the player guesses all the letters correctly before they use up their allotted guesses, they win!"
    },
    {
        id: 2,
        title: "Sticky Notes",
        link: "https://b39mz3.csb.app/",
        thumbnail: "img/sticky-notes-thumbnail.png",
        skills: ["ES6", "JSX"],
        tools: ["CodeSandbox"],
        description: "This interactive app lets users create, delete, and modify notes on their computer to track to-do items."
    },
    {
        id: 3,
        title: "Unplugged Retreat",
        link: "https://cbsumo305.github.io/unplugged-retreat/",
        thumbnail: "img/unplugged-thumbnail.png",
        skills: ["HTML5", "CSS3", "Responsive Typography"],
        tools: ["Photoshop", "CodeSandbox"],
        description: "This responsive 3-page website was built from a design comp and adapts to mobile, tablet, and desktop screens."
    },
    
    {
        id: 5,
        title: "Unplugged Retreat",
        link: "",
        thumbnail: "",
        skills: ["ES6", "JSX"],
        tools: ["GitHub", "Photoshop"],
        description: ""
    },

    {
        id: 4,
        title: "",
        link: "",
        thumbnail: "",
        skills: ["ES6", "JSX"],
        tools: ["CodeSandbox"],
        description: ""
    }

    
];

export default projects;

//Build a 3 - page responsive website from a design comp that adapts to mobile, tablet, and desktop screens.

    //Skills: CSS3, Flexbox, HTML5, Media Queries, Responsive Typography

//Tools: GitHub, Photoshop, Text editors