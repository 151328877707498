import React from "react";
import ResLink from "./ResLink.js";

const ResLinkList = (props) => {


    const renderLink = (link) => {

        return (

            <ResLink key={link.id} links={props.links} link={link} onType={props.onType} onDelete={props.onDelete} preview={props.preview}/>
        );
    }


    const linkElements = props.links.map(renderLink);


    return (
    <div className="flex-row template-reveal hunny-p"> {linkElements}</div>)
    

};

export default ResLinkList;
